<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">销售员列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="saleName"
                type="text"
                clearable
                size="small"
                placeholder="请输入姓名"
              />
            </div>
            <div title="交接人姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">交接人姓名:</span>
              <el-input
                v-model="turnOverSaleName"
                type="text"
                clearable
                size="small"
                placeholder="请输入交接人姓名"
              />
            </div>
            
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="saleMobile"
                type="text"
                clearable
                size="small"
                placeholder="请输入手机号"
              />
            </div>
             <div title="人员类型" class="searchboxItem ci-full">
              <span class="itemLabel">人员类型:</span>
              <el-select size="small" clearable v-model="saleType" placeholder="请选择">
                <el-option
                  v-for="item in PersonnelTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" round @click="add()">新增</el-button>
          </div>
          <!-- <div class="df ci-full">
            <el-button class="bgc-bv" round @click="add()">新增职工</el-button>
            <el-button class="bgc-bv" round @click="Exportcomp">导入职工</el-button>
          </div>-->
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="center"
                show-overflow-tooltip
                prop="saleName"
              />
              <el-table-column
                label="手机号"
                align="center"
                show-overflow-tooltip
                prop="saleMobile"
              />
              <el-table-column
                label="人员类型"
                align="center"
                show-overflow-tooltip
                prop="saleType"
              >
                <template slot-scope="scope">
                  {{ getAllSaleType(scope.row) }}
                </template>
              </el-table-column>

              <el-table-column
                label="服务范围"
                align="center"
                show-overflow-tooltip
              >
              <template slot-scope="scope">
                  {{scope.row.saleType == '20' ? scope.row.allAreaName : scope.row.compCount}}
                </template>
              </el-table-column>
               <el-table-column
                label="是否交接"
                align="center"
                show-overflow-tooltip
                prop="turnOverState"
              >
              <template slot-scope="scope">
                  {{scope.row.turnOverState ? '已交接' : '未交接'}}
                </template>
              </el-table-column>
              <el-table-column
                label="交接人姓名"
                align="center"
                show-overflow-tooltip
                prop="turnOverSaleName"
              />
              <el-table-column label="操作" align="center" width="200px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    @click="onEditor(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="onDelete(scope.row)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="onSet(scope.row)"
                    >设置</el-button
                  >
                  <el-button
                    type="text"
                    :disabled="scope.row.saleType != '10'"
                    size="mini"
                    @click="connectHandle(scope.row)"
                    >交接</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 新增/编辑  弹窗 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      class="relation_jg_dialog"
      :close-on-click-modal="false"
      @close="doClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="8rem"
        class="demo-ruleForm"
        style="width: 90%"
      >
        <el-form-item label="姓名：" prop="saleName">
          <el-input
            v-model="ruleForm.saleName"
            size="small"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="saleMobile">
          <el-input
            v-model="ruleForm.saleMobile"
            size="small"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item label="人员类型：" prop="saleType">
          <el-select
            v-model="ruleForm.saleType"
            placeholder="请选择人员类型"
            size="small"
            clearable
          >
            <el-option
              v-for="item in PersonnelTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: center">
        <el-button @click="doClose()">取消</el-button>
        <el-button class="bgc-bv" @click="submitForm('ruleForm')">
          确定
        </el-button>
      </div>
    </el-dialog>
    <!-- 设置  弹窗 -->
    <el-dialog
      title="设置区域"
      :visible.sync="DialogVisibleSet"
      width="50%"
      center
      class="relation_jg_dialog"
      :close-on-click-modal="false"
      @close="doCloseSetOut"
    >
      <section class="CourseTablekponit">
        <div class="operationControl" style="border:0">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem">
              <span class="itemLabel">姓名:</span>
              <span>{{rowData.saleName}}</span>
            </div>
            <div>
              <el-button class="bgc-bv" @click="onSetEditor('add')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="dataTrainList"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="培训类型"
                align="center"
                show-overflow-tooltip
                prop="trainTypeNamePath"
              />
              <el-table-column
                label="区域"
                align="center"
                show-overflow-tooltip
                prop="allAreaName"
              />

              <el-table-column label="操作" align="center" width="200px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    style="padding: 0px 15px"
                    type="text"
                    size="mini"
                    @click="onSetEditor('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    style="padding: 0px 15px"
                    type="text"
                    size="mini"
                    @click="onSetDelete(scope.row)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </section>
      <el-dialog
        width="45%"
        :title="innerSetTitle"
        :visible.sync="innerVisibleSet"
        append-to-body
      >
        <el-form
          :model="ruleFormSet"
          :rules="rulesSet"
          ref="ruleFormSet"
          label-width="8rem"
          class="demo-ruleForm"
          style="width: 90%"
        >
          <el-form-item label="培训类型：" prop="trainTypeId">
            <el-cascader
              v-model="ruleFormSet.trainTypeId"
              placeholder="请选择培训类型"
              :options="trainTypeList"
              :props="propsTrainType"
              size="small"
              clearable
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="选择区域：" prop="areaId">
            <el-cascader
              v-model="ruleFormSet.areaId"
              placeholder="请选择区域"
              :options="cityTree"
              :props="propsarea1"
              size="small"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
        </el-form>
        <div style="display: flex; justify-content: center">
          <el-button @click="doCloseSet()">取消</el-button>
          <el-button class="bgc-bv" @click="submitFormSet('ruleFormSet')">
            确定
          </el-button>
        </div>
      </el-dialog>
    </el-dialog>
     <!-- 交接  弹窗 -->
    <el-dialog
      title="交接"
      :visible.sync="connectData.connectState"
      width="800px"
      center
      :close-on-click-modal="false"
      @close="connectData = $options.data().connectData"
    >
      <el-form
        :model="connectData"
        ref="connectForm"
        label-width="85px"
      >
      <template v-if="isConnectList">
        <template v-if="connectData.connectList.length>0">
          <el-row v-for="item in connectData.connectList" :key="item.groupAreaId">
            <el-col :span="6">
              <el-form-item label="行政区划:">
                {{!!item.areaName?item.areaName:'无区划'}}
              </el-form-item>
            </el-col>
           <el-col :span="9">
              <el-form-item label="已交接人:" prop="turnOverSaleId">
                <el-select
                  v-model="item.turnOverSaleId"
                  placeholder="请选择市场负责人"
                  style="width:100%"
                  size="small"
                  disabled
                  clearable
                >
                  <el-option
                    v-for="e in marketLeaderList"
                    :key="e.saleId"
                    :label="e.saleName"
                    :value="e.saleId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="交接时间:" prop="saleName">
                <el-date-picker
                    clearable
                    v-model="item.turnOverTime"
                    type="date"
                    disabled
                    size="small"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <template v-if="connectData.unConnectList.length>0">
          <el-row v-for="(item,index) in connectData.unConnectList" :key="item.areaId">
            <el-col :span="6">
              <el-form-item label="行政区划:">
                {{item.areaId=='0'?'无区划':item.areaName}}
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item 
                label="交接人:" 
                :prop="'unConnectList.' + index + '.turnOverSaleId'"
                :rules="[
                  { required: true, message: '请选择交接人', trigger: 'change' },
                ]"
                >
                <el-select
                  v-model="item.turnOverSaleId"
                  placeholder="请选择交接人"
                  style="width:100%"
                  size="small"
                  clearable
                >
                  <el-option
                    v-for="e in marketLeaderList"
                    :key="e.saleId"
                    :label="e.saleName"
                    :value="e.saleId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item 
                label="交接时间:" 
                :prop="'unConnectList.' + index + '.turnOverTime'"
                :rules="[
                  { required: true, message: '请选择交接时间', trigger: 'change' },
                ]"
                >
                <el-date-picker
                    clearable
                    v-model="item.turnOverTime"
                    type="date"
                    size="small"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </template>
      <template v-else>
          <div style="text-align:center;margin-bottom: 15px;">暂无可交接数据</div>
      </template>
      </el-form>
      <div style="display: flex; justify-content: center">
        <el-button @click="connectData = $options.data().connectData">取消</el-button>
        <el-button v-if="isConnectList" class="bgc-bv" @click="submitConnectForm">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "marketUserList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    var Phone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的联系电话"));
      } else {
        callback();
      }
    };
    return {
      saleMobile: "", //人员手机号
      saleName: "", //人员姓名
      saleType:'', //人员类型
      turnOverSaleName:'', //交接人姓名
      cityTree: [], // 区划
      propsarea1: {
        value: "value",
        label: "label",
        disabled: "disabled",
        emitPath: false,
        // checkStrictly: true,
        multiple: true,
      },
      trainTypeList: [],
      propsTrainType: {
        value: "id",
        label: "label",
        emitPath: false,
        // checkStrictly: true,
      },
      //人员类型
      PersonnelTypeList: [],
      centerDialogVisible: false,
      dialogTitle: "", // 弹框的标题
      ruleForm: {
        saleMobile: "", // 手机号
        saleName: "", // 姓名
        saleType: "", // 人员类型
      },
      rules: {
        saleName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        saleMobile: [{ required: true, validator: Phone, trigger: "blur" }],
        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        saleType: [
          { required: true, message: "请选择人员类型", trigger: "change" },
        ],
        areaId: [{ required: true, message: "请选择区域", trigger: "change" }],
      },
      //设置弹窗
      DialogVisibleSet: false, //设置区域外层dialog
      innerVisibleSet: false, //设置区域内层dialog
      innerSetTitle: "", //设置区域内层dialogTitle
      ruleFormSet: {
        trainTypeId: "", // 培训类型id
        areaId: "", // 区划id
      },
      rulesSet: {
        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" },
        ],
        areaId: [{ required: true, message: "请选择区域", trigger: "change" }],
      },
      dataTrainList: [],//获取培训数据
      rowData:{}, //设置获取数据
      // 区域客服交接区域情况弹窗数据
      connectData:{
        connectState:false,//弹窗状态
        saleId:'',
        connectList:[],
        unConnectList:[],
      },
      marketLeaderList:[]
    };
  },
  computed: {
    isConnectList(){
      if(Array.isArray(this.connectData.connectList) && this.connectData.connectList.length||
      Array.isArray(this.connectData.unConnectList) && this.connectData.unConnectList.length){
        return true
      }else{
        return false
      }
      
    }
  },
  mounted() {},
  created() {
    this.getcity();
    this.getTrainTypeList();
    this.getAllSaleList();
    this.getMarketLeader();
  },

  methods: {
    // 交接弹窗
    async connectHandle(row){
      const { saleId, turnOverState } = row
      this.connectData.saleId = saleId
      this.connectData.connectList = (await this.$post("/biz/user/sale/turnOver/detail", {saleId})).data//已交接
      if(!turnOverState){
        this.connectData.unConnectList =(await this.$post("/biz/user/sale/turnOver/areaList", {saleId})).data//未交接
        this.connectData.unConnectList = this.connectData.unConnectList.map(item=>{
          return {
            areaId:item.groupAreaId,
            areaName:item.areaName,
            turnOverSaleId:'',
            turnOverTime:''
          }
        })
      }else{
        this.connectData.unConnectList = []
      }
      this.connectData.connectState = true
    },
    // 交接提交
    submitConnectForm(){
      this.$refs['connectForm'].validate((valid) => {
        if (valid) {
          const params = {
            saleId:this.connectData.saleId,
            areaDTOS:this.connectData.unConnectList
          }
          this.$post('/biz/user/sale/turnOver', params)
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.connectData = this.$options.data().connectData
              this.getData(-1);
            }
          })
          .catch((err) => {
            return;
          });
        }
      });
    },
    //获取交接人
    getMarketLeader() {
      this.$post('/biz/user/sale/selectList', { saleType: "10" })
        .then((ret) => {
          this.marketLeaderList = ret.data || [];
        })
        .catch((err) => {
          this.marketLeaderList = [];
          return;
        });
    },
    // 修改
    onEditor(row) {
      this.ruleForm = {
        saleId: row.saleId, // 销售员id
        saleMobile: row.saleMobile, // 手机号
        saleName: row.saleName, // 姓名
        saleType: row.saleType, // 人员类型
      };
      this.centerDialogVisible = true;
      this.dialogTitle = "编辑人员";
    },
    // 获取人员类型列表
    getAllSaleList() {
      let list = this.$checkSessionsObj("dictionaryJson").SALETYPE;
      for (const key in list) {
        const element = list[key];
        this.PersonnelTypeList.push({
          value: key,
          label: element,
        });
      }
    },
    // 获取每条数据的人员类型名称
    getAllSaleType(row) {
      // SALETYPE
      ;
      let allSaleType = row.saleType.split(",");
      let list = this.$checkSessionsObj("dictionaryJson").SALETYPE;
      let str = "";
      for (const key in allSaleType) {
        const element = allSaleType[key];
        if (str) {
          str = str + "," + list[element];
        } else {
          str = list[element];
        }
      }
      return str;
    },
    // 删除
    onDelete(row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$post("/biz/user/sale/deleteSaleChange", {
            saleId: row.saleId,
          }).then((ret) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getData(-1);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //获取数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        saleName: this.saleName || null,
        saleMobile: this.saleMobile || null,
      };
      if(this.saleType) {
        params.saleType = this.saleType
      }
      if(this.turnOverSaleName) {
        params.turnOverSaleName = this.turnOverSaleName
      }
      this.doFetch({
        url: "/biz/user/sale/pageListChange",
        params,
        pageNum,
      });
    },
    add() {
      this.centerDialogVisible = true;
      this.dialogTitle = "新增人员";
    },
    //
    handle(id) {
      this.dialogRightMarketUser = id;
    },
    /**
     *  行政区划
     */
    getcity() {
      this.$post("/sys/area/tree?areaLevel=2").then((ret) => {
        let data = ret.data || [];
        data.map((el) => {
          el.disabled = false;
        });
        this.cityTree = [...data] || [];
      });
    },
    /**
     * tree接口
     */
    getTrainTypeList() {
      this.$post("/sys/category/train-type/tree")
        .then((res) => {
          let data = res.data || [];
          this.trainTypeList = data;
        })
        .catch((err) => {
          return;
        });
    },
    doClose() {
      this.centerDialogVisible = false;
      this.$refs["ruleForm"].resetFields();
      this.ruleForm = {};
    },
    //确认
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    doAjax() {
      let url = "/biz/user/sale/addSaleChange";
      let params = {
        saleMobile: this.ruleForm.saleMobile,
        saleName: this.ruleForm.saleName,
        saleType: this.ruleForm.saleType,
      };
      if (this.ruleForm.saleId) {
        params.saleId = this.ruleForm.saleId;
        url = "/biz/user/sale/updateSaleChange";
      }

      this.$post(url, params)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.doClose();
            this.getData();
          }
        })
        .catch((err) => {
          return;
        });
    },
    //设置
    onSet(row) {
      if (row.saleType == "20") {
        this.DialogVisibleSet = true;
        this.rowData = row;
        this.getDataTrain(row.saleId);
      } else {
        this.$router.push({
          path:'/web/UnitList',
          query:{
            saleId:row.saleId
          }
        })
      }
    },
    // 删除
    onSetDelete(row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$post("/biz/user/sale/deleteSetupChange", {
            saleId: row.saleId,
            trainTypeId:row.trainTypeId
          }).then((ret) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getDataTrain(row.saleId);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getDataTrain(saleId) {
      this.$post("/biz/user/sale/selecTrainAreaListBySale", { saleId }).then(
        (ret) => {
          console.log(ret);
          this.dataTrainList = ret.data || [];
        }
      );
    },
    //设置区域编辑
    onSetEditor(stu, row = {}) {
      ;
      this.innerVisibleSet = true;
      if (stu == "add") {
        this.innerSetTitle = "新增";
        this.$refs["ruleFormSet"].resetFields();
        this.ruleFormSet = {};
      } else {
        this.innerSetTitle = "编辑";
        this.ruleFormSet = {
          areaId: row.allAreaId.split(","), // 区划id
          trainTypeId: row.trainTypeId, // 培训类型id
          allRegionId:row.allRegionId,
        };
      }
    },
      //确认
    submitFormSet(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doAjaxSet();
        }
      });
    },
    doAjaxSet() {
      let url = "/biz/user/sale/addSetupChange";
      let params = {
        saleId: this.rowData.saleId,
        areaList:this.ruleFormSet.areaId,
        trainTypeId: this.ruleFormSet.trainTypeId,
        saleType:this.rowData.saleType
      };
      if (this.ruleFormSet.allRegionId) {
        params.allRegionId = this.ruleFormSet.allRegionId.split(",");
        url = "/biz/user/sale/updateSetupChange";
      }

      this.$post(url, params)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.doCloseSet();
            this.getDataTrain(this.rowData.saleId);
          }
        })
        .catch((err) => {
          return;
        });
    },
    doCloseSet() {
      this.innerVisibleSet = false;
      this.$refs["ruleFormSet"].resetFields();
      this.ruleFormSet = {};
      
    },
    doCloseSetOut() {
      this.getData(-1)
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
// .relation_jg_dialog {
//   .el-dialog--center .el-dialog__body {
//     padding: 25px 20px 30px;
//   }
//   .el-table--border {
//     border-bottom: 1px solid #ebeef5;
//     th {
//       border-right: 1px solid #ebeef5;
//     }
//   }
//   .table_box {
//     width: 42%;
//     .table_box_top {
//       margin-bottom: 10px;
//     }
//     // border: 1px red solid;
//     .list_top {
//       padding: 15px;
//       text-align: center;
//       border: 1px solid #ebeef5;
//       border-bottom: 0;
//     }
//     .list_search {
//       border: 1px solid #ebeef5;
//       border-top: 0;
//       border-bottom: 0;
//       padding: 0 10px 15px;
//     }
//   }
//   // 左右按钮操作
//   .btn_arrow {
//     padding: 1.25rem;
//     > div {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//     }
//   }
//   .relation_page {
//     .el-pagination__sizes {
//       .el-select {
//         top: -3px;
//       }
//     }
//   }
// }
//
</style>
